import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="IRStrat I"/>
            <Banner title="IRStrat I"/>
            <section className="welcome_section_area propuesta">
                <div class="page-content about-icon-box padding-100 padding-bottom-50" className="container">
                    <div class="container">
                        <div class="row">
                            <p>It focuses on maximizing the delivery quality and narrative of your IR materials for solid positioning
                            and servicing, comprising of:</p>
                            <div class="col-md-6 mb-5">
                                <div class="icon-box1">
                                    <i class="pe-7s-config"></i>
                                    <div className="media strategy_item align-items-center strategy_item_service">
                                        <div className="round_icon no_round_icon"><i className="flaticon-research"></i>
                                        </div>
                                        <div className="media-body media-body-service">
                                            <h4 class="title_service">Reports and Press Releases</h4>
                                        </div>
                                    </div>
                                    {/* <h4 class="panel-title"></h4> */}

                                    <ul className="list_style">
                                        <li><i className="fa fa-angle-right"></i>Graphic corporate identity
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Drafting of boiler plate and corporative safe harbor statement
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Structuring and graphical development of the earnings release
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Structuring and graphical editing of the annual report
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Interactive deployment of the annual report in HTML
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Interactive deployment of the sustainability report in HTML
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 mb-5">
                                <div class="icon-box1">
                                    <i class="pe-7s-monitor"></i>
                                    <div className="media strategy_item align-items-center strategy_item_service">
                                        <div className="round_icon no_round_icon"><i className="flaticon-money-1"></i>
                                        </div>
                                        <div className="media-body media-body-service">
                                            <h4 class="title_service">IR website</h4>
                                        </div>
                                    </div>
                                    {/* <h4 class="panel-title">Sitio de RI</h4> */}
                                    <ul className="list_style">
                                        <li><i className="fa fa-angle-right"></i>Structuring and graphical development
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Content drafting</li>
                                        <li><i className="fa fa-angle-right"></i>Optimized platform for mobile devices
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Interactive graphics (includes benchmarking with other issuers) and ticker
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Interactive tools (valuation multiples, key performance indicators and stock information)
                                        </li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="icon-box1">
                                    <i class="pe-7s-phone"></i>
                                    <div className="media strategy_item align-items-center strategy_item_service">
                                        <div className="round_icon no_round_icon"><i className="fa fa-handshake-o"></i>
                                        </div>
                                        <div className="media-body media-body-service">
                                            <h4 class="title_service">Materials for investors</h4>
                                        </div>
                                    </div>
                                    {/* <h4 class="panel-title">Materiales para inversionistas</h4> */}
                                    <ul className="list_style">
                                        <li><i className="fa fa-angle-right"></i>Structuring and graphical development of the investor presentation
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Drafting, structuring and graphical editing of the fact sheet
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Development of Adwords
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="icon-box1">
                                    <i class="pe-7s-headphones"></i>
                                    <div className="media strategy_item align-items-center strategy_item_service">
                                        <div className="round_icon no_round_icon"><i className="flaticon-graph"></i>
                                        </div>
                                        <div className="media-body media-body-service">
                                            <h4 class="title_service">IR Edge 3.0 services</h4>
                                        </div>
                                    </div>
                                    {/* <h4 class="panel-title">Servicios IR Edge 3.0</h4> */}
                                    <ul className="list_style">
                                        <li><i className="fa fa-angle-right"></i>Web Analytics</li>
                                        <li><i className="fa fa-angle-right"></i>Dedicated servers in the cloud
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
